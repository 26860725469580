<template>
<v-app>
  <v-container>
    <h1 class="mt-5 mb-5">Upload New Data</h1>
    <h4 class="mb-5">
      Select study.csv and participants.csv simultaneously by clicking "choose files", and selecting both .csv files.
    </h4>
    <!-- <form @submit.prevent="onSubmit" enctype="multipart/form-data"> -->
      <input type="file" ref="study" multiple @change=onSelect />
      <br>
      <v-btn v-on:click="onSubmit" class="ma-2 mt-5 mb-10" color="primary">
        Submit
      </v-btn>
    <!-- </form> -->
    <h5>{{ message }}</h5>
  </v-container>

  <v-divider></v-divider>

  <div v-if="!loadingData">

  <v-container fluid class="mb-5">
    <h1 class="mt-5 mb-5">Studies</h1>
    <v-data-table :headers="headersStudies" :items="studies" :items-per-page="10" class="mr-5 ml-5">
      <template v-slot:item="row">
        <tr>
          <td>{{row.item.principal_investigator}}</td>
          <td>{{row.item.doi}}</td>
          <td>{{row.item.sample_size_study}}</td>
          <td>{{row.item.sample_size_available}}</td>
          <td>{{row.item.acute_stress_test}}</td>
          <td>{{row.item.time_of_day}}</td>
          <td>{{row.item.questionnaires_names}}</td>
          <td>
            <v-btn class="mx-2" @click="onButtonDelete(row.item)">
              <v-icon dark>mdi-delete</v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-container>

  <v-divider></v-divider>

  <v-container fluid class="mt-5">
    <h1 class="mb-5">Requests</h1>
    <v-data-table :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :headers="headersRequests" :items="requests" :items-per-page="10" class="mr-5 ml-5">
      <template v-slot:item="row">
        <tr>
          <td>{{row.item.reqId}}</td>
          <td>{{row.item.date | formatDate}}</td>
          <td>
            <v-btn class="mx-2" @click="onButtonDownload(row.item)">
              <v-icon dark>mdi-download</v-icon>
            </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-container>

  </div>
  <div v-else>
    <v-progress-circular
      indeterminate
      color="primary"
    ></v-progress-circular>
  </div>

</v-app>
</template>

<script>
import axios from 'axios';
import {
  mapActions,
  mapState
} from "vuex";

export default {
  name: "Admin",
  data() {
    return {
      file: "",
      message: "",
      sortBy: "date",
      sortDesc: true,
      headersStudies: [{
          text: "Principal Investigator",
          align: "start",
          sortable: false,
          value: "principal_investigator",
        },
        {
          text: "DOI",
          value: "doi",
          align: "center"
        },
        {
          text: "Sample Size Study",
          value: "sample_size_study",
          align: "center"
        },
        {
          text: "Sample Size Directly Available",
          value: "sample_size_available",
          align: "center"
        },
        {
          text: "Acute Stress Test",
          value: "acute_stress_test",
          align: "center"
        },
        {
          text: "Time of Day",
          value: "time_of_day",
          align: "center"
        },
        {
          text: "Questionnaires",
          value: "questionnaires_names",
          align: "center"
        },
        {
          text: "Delete Study",
          align: "center"
        }
      ],
      headersRequests: [{
          text: "Request ID",
          value: "reqId",
          align: "center"
        },
        {
          text: "Request date",
          value: "date",
          align: "center"
        },
        {
          text: "Download requested data",
          align: "center"
        },
      ],
    }
  },
  computed: {
    ...mapState([
      "studies",
      "requests",
      "loadingData",
    ])
  },
  methods: {
    ...mapActions([
      "queryStudies",
      "queryRequests",
      "deleteStudyParticipants",
    ]),
    onSelect() {
      const files = this.$refs.study.files;
      this.files = files;
    },
    async onSubmit() {

      const formData = new FormData();
      for (const i of Object.keys(this.files)) {
        formData.append('files', this.files[i])
      }
      try {
        await axios.post('/api/upload', formData);
        this.message = 'uploaded!';
        this.queryStudies({
          query: {}
        });
        this.queryRequests();
        this.loadingData = false; 
      } catch (err) {
        console.log(err);
        this.message = "failed..";
      }
    },
    onButtonDownload(query) {
      axios.post("/api/adminDownloadParticipants", query.query, {
          responseType: 'blob'
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', query.reqId + '_participants.csv');
          document.body.appendChild(link);
          link.click();
        });

        axios.post("/api/adminDownloadStudies", query.query, {
          responseType: 'blob'
        })
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', query.reqId + '_studies.csv');
          document.body.appendChild(link);
          link.click();
        });

    },
    onButtonDelete(query) {
      this.deleteStudyParticipants(query);
    }
  },
  mounted() {
    this.queryStudies({
      query: {}
    });
    this.queryRequests();
    this.loadingData = false; 
  },
}
</script>
